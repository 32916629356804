var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"50px","overflow-y":"auto","height":"74vh"}},[_c('h2',[_vm._v("Настройки времени хранения данных")]),_c('div',[_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Лог-файлы на диске ")]),_c('el-col',{attrs:{"span":6}},[_vm._v(" Период хранения "),_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":1,"max":365},model:{value:(_vm.systemSettings.logFilesStoragePeriod),callback:function ($$v) {_vm.$set(_vm.systemSettings, "logFilesStoragePeriod", $$v)},expression:"systemSettings.logFilesStoragePeriod"}}),_vm._v(" дней ")],1),_c('el-col',{attrs:{"span":5}},[_vm._v(" Запускать в "),_c('el-time-select',{staticStyle:{"width":"100px"},attrs:{"size":"small","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:15',
            end: '23:59'
          }},model:{value:(_vm.systemSettings.logFilesClearTime),callback:function ($$v) {_vm.$set(_vm.systemSettings, "logFilesClearTime", $$v)},expression:"systemSettings.logFilesClearTime"}}),_vm._v(" по МСК ")],1)],1),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Лог в базе данных ")]),_c('el-col',{attrs:{"span":6}},[_vm._v(" Период хранения "),_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":1,"max":365},model:{value:(_vm.systemSettings.logDatabaseStoragePeriod),callback:function ($$v) {_vm.$set(_vm.systemSettings, "logDatabaseStoragePeriod", $$v)},expression:"systemSettings.logDatabaseStoragePeriod"}}),_vm._v(" дней ")],1),_c('el-col',{attrs:{"span":5}},[_vm._v(" Запускать в "),_c('el-time-select',{staticStyle:{"width":"100px"},attrs:{"size":"small","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:15',
            end: '23:59'
          }},model:{value:(_vm.systemSettings.logDatabaseClearTime),callback:function ($$v) {_vm.$set(_vm.systemSettings, "logDatabaseClearTime", $$v)},expression:"systemSettings.logDatabaseClearTime"}}),_vm._v(" по МСК ")],1)],1),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Файловое хранилище ")]),_c('el-col',{attrs:{"span":6}},[_vm._v(" Период хранения "),_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":1,"max":365},model:{value:(_vm.systemSettings.filesStoragePeriod),callback:function ($$v) {_vm.$set(_vm.systemSettings, "filesStoragePeriod", $$v)},expression:"systemSettings.filesStoragePeriod"}}),_vm._v(" дней ")],1),_c('el-col',{attrs:{"span":5}},[_vm._v(" Запускать в "),_c('el-time-select',{staticStyle:{"width":"100px"},attrs:{"size":"small","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:15',
            end: '23:59'
          }},model:{value:(_vm.systemSettings.filesClearTime),callback:function ($$v) {_vm.$set(_vm.systemSettings, "filesClearTime", $$v)},expression:"systemSettings.filesClearTime"}}),_vm._v(" по МСК ")],1)],1),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Сырые данные внешних пользователей ")]),_c('el-col',{attrs:{"span":6}},[_vm._v(" Период хранения "),_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":1,"max":365},model:{value:(_vm.systemSettings.externalUsersDataStoragePeriod),callback:function ($$v) {_vm.$set(_vm.systemSettings, "externalUsersDataStoragePeriod", $$v)},expression:"systemSettings.externalUsersDataStoragePeriod"}}),_vm._v(" дней ")],1),_c('el-col',{attrs:{"span":5}},[_vm._v(" Запускать в "),_c('el-time-select',{staticStyle:{"width":"100px"},attrs:{"size":"small","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:15',
            end: '23:59'
          }},model:{value:(_vm.systemSettings.externalUsersDataClearTime),callback:function ($$v) {_vm.$set(_vm.systemSettings, "externalUsersDataClearTime", $$v)},expression:"systemSettings.externalUsersDataClearTime"}}),_vm._v(" по МСК ")],1)],1),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Удаленные записи в базе данных ")]),_c('el-col',{attrs:{"span":6}},[_vm._v(" Период хранения "),_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":1,"max":365},model:{value:(_vm.systemSettings.deletedRowsStoragePeriod),callback:function ($$v) {_vm.$set(_vm.systemSettings, "deletedRowsStoragePeriod", $$v)},expression:"systemSettings.deletedRowsStoragePeriod"}}),_vm._v(" дней ")],1),_c('el-col',{attrs:{"span":5}},[_vm._v(" Запускать в "),_c('el-time-select',{staticStyle:{"width":"100px"},attrs:{"size":"small","clearable":false,"picker-options":{
            start: '00:00',
            step: '00:15',
            end: '23:59'
          }},model:{value:(_vm.systemSettings.deletedRowsClearTime),callback:function ($$v) {_vm.$set(_vm.systemSettings, "deletedRowsClearTime", $$v)},expression:"systemSettings.deletedRowsClearTime"}}),_vm._v(" по МСК ")],1)],1)],1),_c('hr'),_c('h2',[_vm._v("Настройки сопоставления")]),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Настройка временного лага ")]),_c('el-col',{attrs:{"span":6}},[_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":1,"max":365},model:{value:(_vm.systemSettings.timeLag),callback:function ($$v) {_vm.$set(_vm.systemSettings, "timeLag", $$v)},expression:"systemSettings.timeLag"}}),_vm._v(" минут ")],1)],1),_c('hr'),_c('h2',[_vm._v("Настройки блокировки учетной записи")]),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Количество попыток аутентификации учетной записи до применения временной блокировки ")]),_c('el-col',{attrs:{"span":6}},[_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":0,"max":10000},model:{value:(_vm.systemSettings.maxAttemptsTempLock),callback:function ($$v) {_vm.$set(_vm.systemSettings, "maxAttemptsTempLock", $$v)},expression:"systemSettings.maxAttemptsTempLock"}})],1),_c('el-col',{attrs:{"span":6}},[_vm._v(" Временная блокировка "),_c('el-time-select',{staticStyle:{"width":"120px"},attrs:{"size":"small","clearable":false,"picker-options":{
          start: '00:00',
          step: '00:01',
          end: '23:59'
        }},model:{value:(_vm.systemSettings.tempLockDuration),callback:function ($$v) {_vm.$set(_vm.systemSettings, "tempLockDuration", $$v)},expression:"systemSettings.tempLockDuration"}}),_vm._v(" , минут ")],1)],1),_c('el-row',{staticClass:"data-storage-row"},[_c('el-col',{attrs:{"span":6}},[_vm._v(" Количество попыток аутентификации учетной записи до применения постоянной блокировки ")]),_c('el-col',{attrs:{"span":6}},[_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","controls-position":"right","min":0,"max":10000},model:{value:(_vm.systemSettings.maxAttemptsPermanentLock),callback:function ($$v) {_vm.$set(_vm.systemSettings, "maxAttemptsPermanentLock", $$v)},expression:"systemSettings.maxAttemptsPermanentLock"}})],1),_c('el-col',{attrs:{"span":5}})],1),_c('hr'),_c('div',{staticClass:"mt-2",staticStyle:{"text-align":"center"}},[_c('el-button',{attrs:{"size":"small","type":"primary","loading":_vm.loading},on:{"click":_vm.handleSaveSystemSettings}},[_vm._v(" Сохранить ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }